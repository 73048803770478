// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
$separator: "\\:" !default;

$xs: 425px !default;
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$gutter: 0.5rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;

$spacer: 1rem !default;

$breakpoints: (
  "xs": 425px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  
);

$font-family-sans-serif: "Open-Sans", sans-serif;
$font-weight-normal: 400 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
